<template>
  <div id="feedbackSurvey">
    <el-dialog
      :title="feedbackSurveyFormTitle"
      width="880px"
      :visible.sync="feedbackSurveyDialogVisible"
      :close-on-click-modal="false"
      @close="feedbackSurveyDialogClose"
    >
      <el-form
        ref="feedbackSurveyFormRef"
        :model="feedbackSurveyForm"
        :rules="feedbackSurveyFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户名称" prop="customerId">
              <el-select
                v-model="feedbackSurveyForm.customerId"
                placeholder="请选择客户"
                clearable
                :disabled="feedbackSurveyFormTitle !== '新增顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '修改顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '顾客反馈调查记录详情'"
                @change="customerChange"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.customerName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="feedbackSurveyForm.name"
                placeholder="请输入姓名"
                clearable
                :disabled="feedbackSurveyFormTitle !== '新增顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '修改顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '顾客反馈调查记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="phone">
              <el-input
                v-model="feedbackSurveyForm.phone"
                placeholder="请输入联系电话"
                clearable
                :disabled="feedbackSurveyFormTitle !== '新增顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '修改顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '顾客反馈调查记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系地址" prop="contactAddress">
              <el-input
                v-model="feedbackSurveyForm.contactAddress"
                placeholder="请输入联系地址"
                clearable
                :disabled="feedbackSurveyFormTitle !== '新增顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '修改顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '顾客反馈调查记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="反馈内容" prop="feedbackContent">
              <el-input
                v-model="feedbackSurveyForm.feedbackContent"
                placeholder="请输入反馈内容"
                type="textarea"
                :rows="4"
                :disabled="feedbackSurveyFormTitle !== '新增顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '修改顾客反馈调查记录'
                  && feedbackSurveyFormTitle !== '顾客反馈调查记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="处理记录" prop="processingRecords">
              <el-input
                v-model="feedbackSurveyForm.processingRecords"
                placeholder="请输入处理记录"
                type="textarea"
                :rows="4"
                :disabled="feedbackSurveyFormTitle !== '顾客反馈调查记录处理记录'
                  && feedbackSurveyFormTitle !== '顾客反馈调查记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核情况" prop="reviewSituation">
              <el-input
                v-model="feedbackSurveyForm.reviewSituation"
                placeholder="请输入审核情况"
                type="textarea"
                :rows="4"
                :disabled="feedbackSurveyFormTitle !== '顾客反馈调查记录审核情况'
                  && feedbackSurveyFormTitle !== '顾客反馈调查记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户意见和建议" prop="suggestions">
              <el-input
                v-model="feedbackSurveyForm.suggestions"
                placeholder="请输入客户意见和建议"
                type="textarea"
                :rows="4"
                :disabled="feedbackSurveyFormTitle !== '顾客反馈调查记录客户意见和建议'
                  && feedbackSurveyFormTitle !== '顾客反馈调查记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="feedbackSurveyDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="feedbackSurveyFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="客户名称">
        <el-input v-model="searchForm.customerName" placeholder="请输入客户名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['FEEDBACK_SURVEY_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="feedbackSurveyPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="phone" label="联系电话" />
      <el-table-column prop="contactAddress" label="联系地址" />
      <el-table-column prop="feedbackContent" label="反馈内容" />
      <el-table-column prop="processingRecords" label="处理记录" />
      <el-table-column prop="reviewSituation" label="审核情况" />
      <el-table-column prop="suggestions" label="客户意见和建议" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['FEEDBACK_SURVEY_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['FEEDBACK_SURVEY_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['FEEDBACK_SURVEY_HANDLE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleHandle(scope.$index, scope.row)"
          >
            处理记录
          </el-button>
          <el-button
            v-if="checkPermission(['FEEDBACK_SURVEY_REVIEW']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核情况
          </el-button>
          <el-button
            v-if="checkPermission(['FEEDBACK_SURVEY_RECORD']) && scope.row.status === 3"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleSuggest(scope.$index, scope.row)"
          >
            客户意见和建议
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="feedbackSurveyPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addFeedbackSurvey,
  deleteFeedbackSurvey,
  updateFeedbackSurvey,
  selectFeedbackSurveyInfo,
  selectFeedbackSurveyList,
  handleFeedbackSurvey,
  reviewFeedbackSurvey,
  suggestFeedbackSurvey
} from '@/api/sale/feedbackSurvey'
import { selectCustomerList } from '@/api/sale/customer'

export default {
  data () {
    return {
      feedbackSurveyDialogVisible: false,
      feedbackSurveyFormTitle: '',
      feedbackSurveyForm: {
        id: '',
        customerId: '',
        name: '',
        phone: '',
        contactAddress: '',
        feedbackContent: '',
        processingRecords: '',
        reviewSituation: '',
        suggestions: '',
        status: '',
        taskId: ''
      },
      feedbackSurveyFormRules: {
        customerId: [{ required: true, message: '客户名称不能为空', trigger: ['blur', 'change']}]
      },
      feedbackSurveyPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        customerName: ''
      },
      customerList: []
    }
  },
  created () {
    selectCustomerList().then(res => {
      this.customerList = res.list
    })
    selectFeedbackSurveyList(this.searchForm).then(res => {
      this.feedbackSurveyPage = res
    })
  },
  methods: {
    feedbackSurveyDialogClose () {
      this.$refs.feedbackSurveyFormRef.resetFields()
    },
    feedbackSurveyFormSubmit () {
      if (this.feedbackSurveyFormTitle === '顾客反馈调查记录详情') {
        this.feedbackSurveyDialogVisible = false
        return
      }
      this.$refs.feedbackSurveyFormRef.validate(async valid => {
        if (valid) {
          if (this.feedbackSurveyFormTitle === '新增顾客反馈调查记录') {
            this.feedbackSurveyForm.id = ''
            this.feedbackSurveyForm.status = 1
            await addFeedbackSurvey(this.feedbackSurveyForm)
          } else if (this.feedbackSurveyFormTitle === '修改顾客反馈调查记录') {
            await updateFeedbackSurvey(this.feedbackSurveyForm)
          } else if (this.feedbackSurveyFormTitle === '顾客反馈调查记录处理记录') {
            this.feedbackSurveyForm.status = 2
            await handleFeedbackSurvey(this.feedbackSurveyForm)
          } else if (this.feedbackSurveyFormTitle === '顾客反馈调查记录审核情况') {
            this.feedbackSurveyForm.status = 3
            await reviewFeedbackSurvey(this.feedbackSurveyForm)
          } else if (this.feedbackSurveyFormTitle === '顾客反馈调查记录客户意见和建议') {
            this.feedbackSurveyForm.status = 4
            await suggestFeedbackSurvey(this.feedbackSurveyForm)
          }
          this.feedbackSurveyPage = await selectFeedbackSurveyList(this.searchForm)
          this.feedbackSurveyDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.feedbackSurveyFormTitle = '新增顾客反馈调查记录'
      this.feedbackSurveyDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFeedbackSurvey(row.id)
        if (this.feedbackSurveyPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.feedbackSurveyPage = await selectFeedbackSurveyList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.feedbackSurveyFormTitle = '修改顾客反馈调查记录'
      this.feedbackSurveyDialogVisible = true
      this.selectFeedbackSurveyInfoById(row.id)
    },
    handleHandle (index, row) {
      this.feedbackSurveyFormTitle = '顾客反馈调查记录处理记录'
      this.feedbackSurveyDialogVisible = true
      this.selectFeedbackSurveyInfoById(row.id)
    },
    handleReview (index, row) {
      this.feedbackSurveyFormTitle = '顾客反馈调查记录审核情况'
      this.feedbackSurveyDialogVisible = true
      this.selectFeedbackSurveyInfoById(row.id)
    },
    handleSuggest (index, row) {
      this.feedbackSurveyFormTitle = '顾客反馈调查记录客户意见和建议'
      this.feedbackSurveyDialogVisible = true
      this.selectFeedbackSurveyInfoById(row.id)
    },
    handleInfo (index, row) {
      this.feedbackSurveyFormTitle = '顾客反馈调查记录详情'
      this.feedbackSurveyDialogVisible = true
      this.selectFeedbackSurveyInfoById(row.id)
    },
    selectFeedbackSurveyInfoById (id) {
      selectFeedbackSurveyInfo(id).then(res => {
        this.feedbackSurveyForm.id = res.id
        this.feedbackSurveyForm.customerId = res.customerId
        this.feedbackSurveyForm.name = res.name
        this.feedbackSurveyForm.phone = res.phone
        this.feedbackSurveyForm.contactAddress = res.contactAddress
        this.feedbackSurveyForm.feedbackContent = res.feedbackContent
        this.feedbackSurveyForm.processingRecords = res.processingRecords
        this.feedbackSurveyForm.reviewSituation = res.reviewSituation
        this.feedbackSurveyForm.suggestions = res.suggestions
        this.feedbackSurveyForm.status = res.status
        this.feedbackSurveyForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFeedbackSurveyList(this.searchForm).then(res => {
        this.feedbackSurveyPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFeedbackSurveyList(this.searchForm).then(res => {
        this.feedbackSurveyPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFeedbackSurveyList(this.searchForm).then(res => {
        this.feedbackSurveyPage = res
      })
    },
    customerChange (value) {
      if (value) {
        let customer = this.customerList.find(item => item.id === value)
        this.feedbackSurveyForm.name = customer.contactPerson
        this.feedbackSurveyForm.phone = customer.phone
        this.feedbackSurveyForm.contactAddress = customer.contactAddress
      } else {
        this.feedbackSurveyForm.name = ''
        this.feedbackSurveyForm.phone = ''
        this.feedbackSurveyForm.contactAddress = ''
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['FEEDBACK_SURVEY_HANDLE']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['FEEDBACK_SURVEY_REVIEW']) && row.status === 2) {
        return 'review'
      } else if (this.checkPermission(['FEEDBACK_SURVEY_RECORD']) && row.status === 3) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
