import axios from '@/common/axios'
import qs from 'qs'

export function addFeedbackSurvey (data) {
  return axios({
    method: 'post',
    url: '/sale/feedbackSurvey/add',
    data: qs.stringify(data)
  })
}

export function deleteFeedbackSurvey (id) {
  return axios({
    method: 'delete',
    url: '/sale/feedbackSurvey/delete/' + id
  })
}

export function updateFeedbackSurvey (data) {
  return axios({
    method: 'put',
    url: '/sale/feedbackSurvey/update',
    data: qs.stringify(data)
  })
}

export function selectFeedbackSurveyInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/feedbackSurvey/info/' + id
  })
}

export function selectFeedbackSurveyList (query) {
  return axios({
    method: 'get',
    url: '/sale/feedbackSurvey/list',
    params: query
  })
}

export function handleFeedbackSurvey (data) {
  return axios({
    method: 'put',
    url: '/sale/feedbackSurvey/handlerReview',
    data: qs.stringify(data)
  })
}

export function reviewFeedbackSurvey (data) {
  return axios({
    method: 'put',
    url: '/sale/feedbackSurvey/reviewerReview',
    data: qs.stringify(data)
  })
}

export function suggestFeedbackSurvey (data) {
  return axios({
    method: 'put',
    url: '/sale/feedbackSurvey/recorderReview',
    data: qs.stringify(data)
  })
}
